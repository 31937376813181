import React, { useEffect } from "react"
import { graphql } from "gatsby"
import BaseLayout from "./baseLayout"
import { object } from "prop-types"
import { Newsbar } from "../components/Newsbar"
import "../styles/main.scss"
import { Masthead } from "../components/Masthead"
import getTemplateData from "../utilities/getTemplateData"
import { getContentForCircleGraph } from "../utilities/updateContent"
import CircleGraph from "../components/CircleGraph"

export const query = graphql`
  query ($slug: String!) {
    ecsPage(slug: { eq: $slug }) {
      content
      id
      slug
    }

    ecsSitemap(slug: { eq: $slug }) {
      id
      content {
        visibleInBreadcrumbs
        label {
          key
          value
        }
        link {
          target
          type
          value
        }
      }
      parent {
        id
      }
    }

    allEcsSitemap {
      nodes {
        id
        content {
          label {
            key
            value
          }
          link {
            queryString {
              preserveQsData
            }
            target
            type
            value
          }
          visibleInBreadcrumbs
        }
        slug
        parent {
          id
        }
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }

    json(fields: { slug: { eq: $slug } }) {
      fields {
        canonicalUrl
      }
      masthead
      components {
        type
        content
        options
      }
    }

    allNewsJson {
      edges {
        node {
          id
          date
          href
          snippet
        }
      }
    }

    allDownloadsJson {
      edges {
        node {
          id
          title
          href
        }
      }
    }
  }
`

const TopicHubPage = ({ data, location }) => {
  const { headData, components, sitewideMessages, globalHeader } =
    getTemplateData(data, location)
  const circleGraphSlices = []

  if (["/", "preview/"].includes(data.ecsPage.slug)) {
    const introHeading =
      data.ecsPage?.content?.components[7]?.segments[0]?.segmentItemDetails[0]?.parameterItemDetails[0]?.dcr?.heading?.replace(
        /<[^>]*>?/gm,
        ""
      )
    data.json.masthead.content = introHeading
  }

  useEffect(() => {
    // remove circle graph texts that were given in DCM
    if (data.ecsPage.slug === "/") {
      const tdsRows = document
        ?.querySelector("#content-link")
        .querySelectorAll(".tds-row")
      for (var i = 1; i < 13; i++) {
        tdsRows[i].remove()
      }
    }
  }, [])
  getContentForCircleGraph(data, circleGraphSlices, "homepage")
  return (
    <>
      <BaseLayout
        head={headData}
        disclaimers={components["Disclaimer"]}
        sitewideMessages={sitewideMessages}
        globalHeader={globalHeader}
      >
        <div className="featured-area">
          <Masthead {...data?.json?.masthead} />
          {components["Dynamic_Cta"]}
          {components["Intro_Content"]}
          <Newsbar>
            {components["stockticker"]}
            {components["highlight"]}
            {components["downloads"]}
            {components["news"]}
          </Newsbar>
          {circleGraphSlices?.length > 0 && (
            <section>
              <CircleGraph
                centerTitle="Our Drivers of Sustained Value"
                slices={circleGraphSlices}
                slug={data.ecsPage.slug}
              />
            </section>
          )}
          <section role="complementary" aria-label="value-drivers">
            {components["valueDrivers"]}
          </section>
        </div>
        <section id="content-link" role="main" tabIndex="-1">
          {components["Link_List"] && (
            <div className="tds-row">{components["Link_List"]}</div>
          )}
          {components["Body_Content"]}
          {components["Page_Promo"]}
        </section>
        {components["Footer_Cap"]}
      </BaseLayout>
    </>
  )
}

export default TopicHubPage

TopicHubPage.propTypes = {
  data: object,
  location: object,
}
