import { node, string } from "prop-types"
import React, { useState, useEffect } from "react"
import "./Masthead.scss"

export function getRandomInt(min, max) {
  const randomBuffer = new Uint32Array(1)

  window?.crypto?.getRandomValues(randomBuffer)

  let randomNumber = randomBuffer[0] / (0xffffffff + 1)

  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(randomNumber * (max - min + 1)) + min
}

export function getImageAlt(random) {
  switch (random) {
    case 1:
      return "Female with white shirt and green polka dot apron touching purple flowers with garden gloves in a greenhouse."
    case 2:
      return "Jewish family along with grandfather sitting at table lighting the menorah."
    case 3:
      return "Man in wheelchair kissing little girl while woman with black dog stands by."
    case 4:
      return "Woman hugging and kissing young black male child with glasses."
    case 5:
      return "Family of four sitting on floor next to couch petting dog."
    default:
      return "Family of four sitting on floor next to couch petting dog."
  }
}

export function Masthead({ content, children }) {
  const [activeImage, setActiveImage] = useState(null)
  const [image, setImage] = useState(null)
  const [altText, setAltText] = useState("")

  useEffect(() => {
    setActiveImage(getRandomInt(1, 5))
  }, [])

  useEffect(() => {
    const newImage = activeImage
      ? `/iw-images/sustainability/Hero/2023-refresh/home-hero-${activeImage}.jpg`
      : null
    setImage(newImage)
    setAltText(getImageAlt(activeImage))
  }, [activeImage])

  return (
    <div id="masthead" role="complementary" aria-label="Masthead Section">
      <div
        role="img"
        className="masthead-home"
        style={{ backgroundImage: image ? `url(${image})` : "none" }}
        aria-label={altText}
      />
      <p
        className="masthead-content"
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
      {children}
    </div>
  )
}

Masthead.propTypes = {
  content: string.isRequired,
  children: node,
}
